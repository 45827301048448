<template>
  <div>服务端错误</div>
</template>

<script>
export default {
  name: "Error500"
};
</script>

<style lang="scss" scoped>
</style>